import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const elementIds = ["rupeni-script", "rupeni-init-script"];

// Expanded list of Rupeni elements to remove
const rupeniSelectors = [
  "#chat-circle",
  "#chat-circle-container",
  "#chat-box",
  "#chat-tagline",
  "#call-overlay",
  "#rupeni-options-container",
  "#chat-box-header",
  "#chat-box-body",
  "#chat-box-footer",
  "#chat-circle-logo",
  "#chat-circle-toggle",
  // Add class selectors as well
  ".chat-circle",
  ".chat-box",
  ".chat-tagline",
];

function removeScript(scriptId: string) {
  const elements = document.querySelectorAll(`#${scriptId}`);
  elements.forEach((element) => {
    if (element) {
      element.parentNode?.removeChild(element);
    }
  });
}

function handleRemoveScripts() {
  // Remove script tags
  for (const id of elementIds) {
    removeScript(id);
  }

  // Remove all Rupeni elements using combined selectors
  rupeniSelectors.forEach((selector) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
      if (element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    });
  });

  // Additional cleanup for any remaining elements
  const bodyChildren = document.body.children;
  Array.from(bodyChildren).forEach((child) => {
    if (child instanceof HTMLElement) {
      const id = child.id || "";
      const className = child.className || "";
      if (
        id.toLowerCase().includes("chat") ||
        id.toLowerCase().includes("rupeni") ||
        className.toLowerCase().includes("chat") ||
        className.toLowerCase().includes("rupeni")
      ) {
        child.remove();
      }
    }
  });

  // Remove any style tags added by Rupeni
  const styles = document.querySelectorAll("style");
  styles.forEach((style) => {
    if (
      style.textContent?.includes("chat-circle") ||
      style.textContent?.includes("chat-box") ||
      style.textContent?.includes("rupeni")
    ) {
      style.remove();
    }
  });

  // Clear initialization flag
  window.__RUPENI_INITIALIZED__ = false;
}

function loadRupeniScript() {
  // Remove any existing scripts first
  handleRemoveScripts();

  const script = document.createElement("script");
  script.setAttribute("id", "rupeni-script");
  script.setAttribute("src", "https://api.rupeni.com/cdn/rupeni-min.js");

  const initScript = document.createElement("script");
  initScript.setAttribute("id", "rupeni-init-script");
  initScript.textContent = `
    window.__RUPENI_INITIALIZED__ = window.__RUPENI_INITIALIZED__ || false;

    function initRupeni() {
      if (window.__RUPENI_INITIALIZED__) return;
      window.__RUPENI_INITIALIZED__ = true;

      var event = new Event('DOMContentLoaded');
      document.dispatchEvent(event);
    }

    setTimeout(initRupeni, 100);
  `;

  try {
    document.head.appendChild(script);
    script.onload = () => {
      document.head.appendChild(initScript);
    };
  } catch (e) {
    console.error("Error appending script:", e);
  }
}

export default function RupeniScript() {
  const location = useLocation();

  useEffect(() => {
    const isRootPath = location.pathname === "/";

    if (isRootPath && !window.__RUPENI_INITIALIZED__) {
      setTimeout(loadRupeniScript, 0);
    }

    return () => {
      handleRemoveScripts();
    };
  }, [location.pathname]);

  return null;
}

declare global {
  interface Window {
    Rupeni?: {
      init: () => void;
    };
    __RUPENI_INITIALIZED__?: boolean;
  }
}
