import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa';
import { bg404 } from '../../assets/images';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 70,
        damping: 10
      }
    }
  };

  return (
    <main className="text-white min-h-screen bg-bg">
      <div className="relative min-h-screen flex items-center justify-center px-4">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${bg404})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.2,
          }}
        ></div>
        
        <motion.div
          className="relative z-10 text-center max-w-2xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div variants={itemVariants}>
            <h1 className="text-[150px] font-bold text-primary leading-none">
              404
            </h1>
          </motion.div>
          
          <motion.div variants={itemVariants}>
            <h2 className="text-4xl font-bold mb-6">
              Page Not Found
            </h2>
          </motion.div>
          
          <motion.p 
            variants={itemVariants}
            className="text-gray-400 text-lg mb-12"
          >
            The page you're looking for doesn't exist or has been moved.
          </motion.p>

          <motion.div variants={itemVariants}>
            <button
              onClick={() => navigate('/')}
              className="flex items-center mt-6 gap-4 relative parent-div hover:cursor-pointer mx-auto"
            >
              <div className="left-2 absolute rounded-full bg-blend w-10 h-10 flex items-center justify-center"></div>
              <div className="top-circle absolute left-0 z-10 rounded-full bg-primary w-10 h-10 flex items-center justify-center">
                <FaArrowRight className="arrow-icon text-secondary" />
              </div>
              <div className="text-primary ml-16">Back to Home</div>
            </button>
          </motion.div>
        </motion.div>
      </div>

      <footer className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white py-4">
        <div className="container mx-auto text-center">
          <p>Copyright © 2025 Rupeni AI</p>
        </div>
      </footer>
    </main>
  );
};

export default NotFound; 