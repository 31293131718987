import { useRef, useState, useEffect } from "react";
import NotificationSound from "../../../assets/sound/notification-sound.wav";

// Single shared instance
const sharedAudio = new Audio(NotificationSound);
sharedAudio.loop = true; // Enable looping

export function useAudio() {
  const audioRef = useRef<HTMLAudioElement>(sharedAudio);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const isPlayingRef = useRef(false);
  const soundTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const playSound = (onTimeout?: () => void) => {
    if (isPlayingRef.current || !hasUserInteracted) {
      return;
    }

    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current
        .play()
        .then(() => {
          isPlayingRef.current = true;

          // Stop sound after 2 minutes
          soundTimeoutRef.current = setTimeout(() => {
            stopSound();
            if (onTimeout) onTimeout();
          }, 120 * 1000); // Changed from 20 to 120 seconds
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          isPlayingRef.current = false;
        });
    }
  };

  const stopSound = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      isPlayingRef.current = false;
    }

    // Clear the timeout if it exists
    if (soundTimeoutRef.current) {
      clearTimeout(soundTimeoutRef.current);
      soundTimeoutRef.current = null;
    }
  };

  useEffect(() => {
    const handleUserInteraction = () => setHasUserInteracted(true);
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      stopSound();
    };
  }, []);

  return {
    audioRef,
    hasUserInteracted,
    playSound,
    stopSound,
  };
}
