import React, { useEffect, useState, MouseEventHandler } from "react";
import { bg, check, feature, floor, rupeni } from "../../assets/images";
import "./styles.css";
import {
  FaRobot,
  FaHome,
  FaUsers,
  FaArrowRight,
  FaFacebook,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { GiEarthAmerica } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { Plan } from "../../utils/types/paymentplans";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ContactUs } from "../../api/contact";
import ReCAPTCHA from "react-google-recaptcha";
import RupeniScript from '../../components/RupeniScript';

const sections = ["home", "features", "plans", "support"];

interface CardProps {
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ children }) => {
  const [rotationY, setRotationY] = useState(0);
  const [rotationX, setRotationX] = useState(0);

  const handleCardMouseMove: MouseEventHandler<HTMLButtonElement> = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const px = x / rect.width;
    const py = y / rect.height;
    const xx = -15 + 30 * px;
    const yy = 15 - 30 * py;
  
    setRotationY(xx);
    setRotationX(yy);
  };
  
  const handleMouseLeave: MouseEventHandler<HTMLButtonElement> = () => {
    setRotationY(0);
    setRotationX(0);
  };

  return (
    <button
  className="bg-secondary p-8 rounded-lg shadow-lg project card transition-transform transform hover:scale-105 hover:border-primary"
  style={{
    transform: `perspective(1000px) rotateY(${rotationY}deg) rotateX(${rotationX}deg)`,
  }}
  onMouseMove={handleCardMouseMove}
  onMouseLeave={handleMouseLeave}
>
  <div className="hover-overlay">{children}</div>
</button>

  );
};

const Landing: React.FC = () => {
  const [activeSection, setActiveSection] = useState("home");
  const navigate = useNavigate();
  const [plans] = useState<Plan[]>([
    {
        "id": 1,
        "name": "Free",
        "price": 0,
        "inclusions": "<ul><li>30 days free trial</li><li>1 chatbot</li><li>Basic Analytics</li></ul>",

        "created_at": "2024-07-02T21:48:52Z"
    },
    {
        "id": 2,
        "name": "Starter",
        "price": 120,
        "inclusions": "<ul><li>Multiple chatbots</li><li>5 domains</li><li>Basic Branding</li><li>Access to multiple AI models</li><li>Collect and export leads</li><li>Widget customization</li><li>Automated Weekly reports</li><li>Custom functions</li></ul>",

        "created_at": "2024-07-10T04:09:13Z"
    },
    {
        "id": 3,
        "name": "Pro",
        "price": 120,
        "inclusions": "<ul><li>For high volume businesses seeking custom solutions, our Pro plan offers priority support and integration help to create specialized bots for your unique requirements. Tailored for large-scale businesses with needs exceeding our existing plans, this plan ensures your traffic management and unique business demands are a priority. For more information or to discuss your specific needs, please get in touch with us.</li></ul>",

        "created_at": "2024-07-10T04:10:52Z"
    },
]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState<string | null>(
    null
  );
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionMessage(null);

    if (!recaptchaToken) {
      setSubmissionMessage("Please complete the reCAPTCHA.");
      setIsSubmitting(false);
      return;
    }

    const result = await ContactUs(
      form.name,
      form.email,
      form.phone,
      form.message
    );

    if (result) {
      setSubmissionMessage("Message sent successfully!");
    } else {
      setSubmissionMessage("Failed to send message. Please try again later.");
    }

    setIsSubmitting(false);
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const features = [
    {
      title: "Gemini, GPT-3.5 & GPT-4 models",
      description:
        "Choose the ideal language model for your needs, ensuring a tailored and optimized user experience.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-bot h-10 w-10 text-violet-500"
        >
          <path d="M12 8V4H8"></path>
          <rect width="16" height="12" x="4" y="8" rx="2"></rect>
          <path d="M2 14h2"></path>
          <path d="M20 14h2"></path>
          <path d="M15 13v2"></path>
          <path d="M9 13v2"></path>
        </svg>
      ),
    },
    {
      title: "Bot Personas",
      description:
        "Add personality to your chatbot with customizable prompts, making interactions more engaging.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-brain-circuit h-10 w-10 text-indigo-500"
        >
          <path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z"></path>
          <path d="M9 13a4.5 4.5 0 0 0 3-4"></path>
          <path d="M6.003 5.125A3 3 0 0 0 6.401 6.5"></path>
          <path d="M3.477 10.896a4 4 0 0 1 .585-.396"></path>
          <path d="M6 18a4 4 0 0 1-1.967-.516"></path>
          <path d="M12 13h4"></path>
          <path d="M12 18h6a2 2 0 0 1 2 2v1"></path>
          <path d="M12 8h8"></path>
          <path d="M16 8V5a2 2 0 0 1 2-2"></path>
          <circle cx="16" cy="13" r=".5"></circle>
          <circle cx="18" cy="3" r=".5"></circle>
          <circle cx="20" cy="21" r=".5"></circle>
          <circle cx="20" cy="8" r=".5"></circle>
        </svg>
      ),
    },
    {
      title: "Chat History",
      description:
        "Benefit from a fully searchable chat archive, keeping all past conversations at your fingertips.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-messages-square h-10 w-10 text-pink-500"
        >
          <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2z"></path>
          <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1"></path>
        </svg>
      ),
    },
    {
      title: "Bring Your Own Data",
      description:
        "Enhance your chatbot's capabilities by importing various file formats and website content.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-database h-10 w-10 text-blue-500"
        >
          <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
          <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
          <path d="M3 12A9 3 0 0 0 21 12"></path>
        </svg>
      ),
    },
    {
      title: "No Code Platform",
      description:
        "Easily integrate an AI chatbot into your website with just one line of code, no coding skills required.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-code-xml h-10 w-10 text-green-500"
        >
          <path d="m18 16 4-4-4-4"></path>
          <path d="m6 8-4 4 4 4"></path>
          <path d="m14.5 4-5 16"></path>
        </svg>
      ),
    },
    {
      title: "Widget Customization",
      description:
        "Tailor the chatbot widget to match your brand's design for a consistent and attractive user experience.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-paint-bucket h-10 w-10 text-yellow-500"
        >
          <path d="m19 11-8-8-8.6 8.6a2 2 0 0 0 0 2.8l5.2 5.2c.8.8 2 .8 2.8 0L19 11Z"></path>
          <path d="m5 2 5 5"></path>
          <path d="M2 13h15"></path>
          <path d="M22 20a2 2 0 1 1-4 0c0-1.6 1.7-2.4 2-4 .3 1.6 2 2.4 2 4Z"></path>
        </svg>
      ),
    },
    {
      title: "Collect & Export Leads",
      description:
        "Seamlessly gather and export leads to ensure you capture every potential customer.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-users-round h-10 w-10 text-teal-500"
        >
          <path d="M18 21a8 8 0 0 0-16 0"></path>
          <circle cx="10" cy="8" r="5"></circle>
          <path d="M22 20c0-3.37-2-6.5-4-8a5 5 0 0 0-.45-8.3"></path>
        </svg>
      ),
    },
    {
      title: "White Labeling",
      description:
        "Boost your AI chatbot's identity with a custom domain, providing a unified and branded communication experience.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-tag h-10 w-10 text-foreground"
        >
          <path d="M12.586 2.586A2 2 0 0 0 11.172 2H4a2 2 0 0 0-2 2v7.172a2 2 0 0 0 .586 1.414l8.704 8.704a2.426 2.426 0 0 0 3.42 0l6.58-6.58a2.426 2.426 0 0 0 0-3.42z"></path>
          <circle cx="7.5" cy="7.5" r=".5" fill="currentColor"></circle>
        </svg>
      ),
    },
    {
      title: "Data Protection",
      description:
        "All Your Data is secured, ensuring it is never used for AI model training.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-folder-lock h-10 w-10 text-red-400"
        >
          <rect width="8" height="5" x="14" y="17" rx="1"></rect>
          <path d="M10 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v2.5"></path>
          <path d="M20 17v-2a2 2 0 1 0-4 0v2"></path>
        </svg>
      ),
    },
  ];

  const containerVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 70, damping: 10 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: "spring", stiffness: 70, damping: 10 },
    },
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const featureContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const dividerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
  };

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
          console.log(`Intersecting section: ${entry.target.id}`);
        }
      });
    };

    const isMobile = window.innerWidth <= 768;
    const observerOptions = {
      threshold: isMobile ? [0.1, 0.2, 0.3, 0.4, 0.5] : [0.3],
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) observer.observe(element);
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) observer.unobserve(element);
      });
    };
  }, []);

 

  const handleNavClick = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsDrawerOpen(false);
    }
  };

  const handleClick = () => {
    navigate("/authentication");
  };

  const handleRegClick = () => {
    navigate("/authentication?tab=registration");
  };

  const modifyInclusions = (inclusions: string, index: number) => {
    if (index === 0) {
      return inclusions.replace(/30 days free trial/, "30 days Money Back Guarantee")
      .replace(/1 chatbot/, "Full refund of plan amount")
      .replace(/Basic Analytics/, "");
      
    } else if (index === 1) {
      return inclusions.replace(
        /<li>5 domains<\/li>/,
        "<li>3 domains</li><li>Unlimited Chats</li>"
      );
    }
    return inclusions;
  };

  const rightContentVariants = {
    hidden: { x: 20, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const sectionHeadingVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const featureCardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const getPriceDisplay = (index: number): string => {
    if (index === 2) {
      return "Custom";
    }
    if (index === 0) {
      return `Money Back Guarantee!`;
    }
    return "";
  };

  return (
    <>
      <RupeniScript />
      <main className="text-white">
        <div className="bg-bg">
          <motion.div
            className={`w-full top-0 left-0 z-40 ${
              isScrolled ? "fixed bg-black" : "relative"
            }`}
            variants={isScrolled ? containerVariants : {}}
            initial="hidden"
            animate="visible"
          >
            <div className="w-full flex flex-col md:flex-row items-center px-6 pt-2 md:px-20">
              <div className="w-full md:flex-1 flex items-center justify-between md:justify-center mb-4 md:mb-0">
                <img
                  src={rupeni}
                  alt="logo"
                  className="md:hidden w-12 md:w-28 mx-4 md:mx-8 "
                />
                <div className="md:hidden">
                  <button
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    className="text-white focus:outline-none"
                  >
                    {isDrawerOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                  </button>
                </div>
                <div className="hidden md:flex flex-wrap gap-4 md:gap-16 tracking-widest items-center justify-center">
                  {sections.slice(0, 2).map((section, index) => (
                    <motion.span
                      key={section}
                      onClick={() => handleNavClick(section)}
                      className={`cursor-pointer transition-transform transform hover:scale-110 ${
                        activeSection === section
                          ? "text-primary border-b-2 border-primary pb-2"
                          : ""
                      }`}
                      variants={itemVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: 0.1 * index }}
                    >
                      {section.charAt(0).toUpperCase() + section.slice(1)}
                    </motion.span>
                  ))}
                  <img
                    src={rupeni}
                    alt="logo"
                    className="w-12 md:w-28 mx-4 md:mx-8 "
                  />
                  {sections.slice(2).map((section, index) => (
                    <motion.span
                      key={section}
                      onClick={() => handleNavClick(section)}
                      className={`cursor-pointer transition-transform transform hover:scale-110 ${
                        activeSection === section
                          ? "text-primary border-b-2 border-primary pb-2"
                          : ""
                      }`}
                      variants={itemVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: 0.1 * (index + 2) }}
                    >
                      {section.charAt(0).toUpperCase() + section.slice(1)}
                    </motion.span>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>

          {isDrawerOpen && (
            <div className="md:hidden fixed top-0 left-0 w-3/4 h-full bg-black z-50">
              <div className="flex flex-col p-4">
                {sections.map((section, index) => (
                  <motion.span
                    key={section}
                    onClick={() => handleNavClick(section)}
                    className={`cursor-pointer transition-transform transform hover:scale-110 my-2 ${
                      activeSection === section
                        ? "text-primary border-b-2 border-primary pb-2"
                        : ""
                    }`}
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.1 * index }}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </motion.span>
                ))}
                <motion.div
                  className="h-12 w-full border-2 rounded-2xl flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-primary hover:border-primary hover:text-black hover:shadow-lg hover:shadow-yellow-500 hover:cursor-pointer mt-4"
                  onClick={() => handleClick()}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ delay: 0.3 }}
                >
                  <span>Login</span>
                </motion.div>
              </div>
            </div>
          )}

          <section
            id="home"
            className="relative h-[80vh] md:h-[50vh] bg-bg pt-32 flex items-center justify-center text-center"
          >
            {/* <img
              src={bg_gif}
              className="w-full h-full absolute top-0 left-0 opacity-5 object-cover"
              alt="Background"
            /> */}
            <div className="relative z-10 container max-w-6xl mx-auto px-4 text-white">
              <h1 className="text-5xl md:text-6xl font-bold mb-4">
                USE RUPENI TO <span className="text-primary">STREAMLINE</span>{" "}
                CUSTOMER INTERACTION
              </h1>
              <p className="text-lg md:text-xl mb-8">
                Empower your business with Rupeni, an AI powered chatbot that
                enhances customer support by answering questions instantly and
                generating valuable leads. Seamlessly integrated into your
                website, Rupeni works round the clock to keep your customers
                satisfied and your sales pipeline full.
              </p>
              <div className="flex gap-5 justify-center">
                <button
                  onClick={handleRegClick}
                  className="bg-primary w-40 border border-secondary text-black px-6 py-3 rounded-lg hover:bg-secondary hover:border-primary  hover:text-white transition-colors"
                >
                  Start free trial
                </button>
                <button
                  onClick={handleClick}
                  className="bg-transparent w-40 text-white border border-primary px-6 py-3 rounded-lg hover:bg-primary hover:text-black transition-colors"
                >
                  Login
                </button>
              </div>
            </div>
          </section>

          <section id="features" className="h-full bg-bg  pt-32">
            <div className="flex items-center justify-center px-4">
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={featureContainerVariants}
                className="w-full max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <motion.div variants={featureCardVariants}>
                    <Card>
                      <div className="flex items-center justify-center w-full pb-4 text-primary icon">
                        <div className="bg-gray-800 p-4 rounded-md">
                          <FaRobot className="w-12 h-12 icon flip" />
                        </div>
                      </div>
                      <h3 className="text-xl font-normal mb-2">
                        Pioneering AI Technology
                      </h3>
                      <p className="text-gray-400">
                        We lead the way as the first in Fiji to develop AI chatbot
                        solutions, pushing the boundaries of technology and
                        innovation.
                      </p>
                    </Card>
                  </motion.div>
                  <motion.div variants={featureCardVariants}>
                    <Card>
                      <div className="flex items-center justify-center w-full pb-4 text-primary icon">
                        <div className="bg-gray-800 p-4 rounded-md">
                          <FaHome className="w-12 h-12 icon flip" />
                        </div>
                      </div>
                      <h3 className="text-xl font-normal mb-2">
                        Proudly Fijian-Made
                      </h3>
                      <p className="text-gray-400">
                        Our AI chatbots are crafted entirely within Fiji,
                        reflecting our commitment to local talent and quality.
                      </p>
                    </Card>
                  </motion.div>
                  <motion.div variants={featureCardVariants}>
                    <Card>
                      <div className="flex items-center justify-center w-full pb-4 text-primary icon">
                        <div className="bg-gray-800 p-4 rounded-md">
                          <FaUsers className="w-12 h-12 icon flip" />
                        </div>
                      </div>
                      <h3 className="text-xl font-normal mb-2">
                        Supporting Local Growth
                      </h3>
                      <p className="text-gray-400">
                        As a 100% locally owned company, we are dedicated to
                        boosting Fiji's economy and fostering local development.
                      </p>
                    </Card>
                  </motion.div>
                  <motion.div variants={featureCardVariants}>
                    <Card>
                      <div className="flex items-center justify-center w-full pb-4 text-primary icon">
                        <div className="bg-gray-800 p-4 rounded-md">
                          <GiEarthAmerica className="w-12 h-12 icon flip" />
                        </div>
                      </div>
                      <h3 className="text-xl font-normal mb-2">
                        Expertise You Can Trust
                      </h3>
                      <p className="text-gray-400">
                        Our skilled team of Fijian developers delivers exceptional
                        AI chatbot solutions, tailored to meet local business
                        needs.
                      </p>
                    </Card>
                  </motion.div>
                </div>
                <motion.div
                  className="self-center md:ml-24"
                  variants={rightContentVariants}
                >
                  <motion.div
                    className="header-right text-3xl md:text-4xl text-primary flex flex-wrap gap-2 md:gap-10 mb-4"
                    variants={textVariants}
                  >
                    <ReactTyped
                      strings={["First in Fiji!", "100% Local owned!"]}
                      typeSpeed={100}
                      backSpeed={50}
                      loop
                    />{" "}
                  </motion.div>
                  <motion.div
                    className="text-lg font-bold"
                    variants={textVariants}
                  >
                    Bringing you AI technology, right at Home
                  </motion.div>
                  <motion.div className="astrodivider" variants={dividerVariants}>
                    <div className="astrodividermask"></div>
                    <span>
                      <i>&#10038;</i>
                    </span>
                  </motion.div>
                  <motion.div className="text-gray-300" variants={textVariants}>
                    Introducing Fiji's first-ever AI chatbot, crafted entirely by
                    local developers. A pioneering tool set to redefine customer
                    engagement. Unlock unparalleled efficiency and seamless
                    interactions with this innovative chatbot. Embrace a new era
                    of communication and watch your business thrive like never
                    before.
                  </motion.div>
                  <motion.div
                    className="flex items-center mt-6 gap-4 relative parent-div hover:cursor-pointer"
                    onClick={() => handleClick()}
                    variants={buttonVariants}
                  >
                    <div className="left-2 absolute rounded-full bg-blend w-10 h-10 flex items-center justify-center"></div>
                    <div className="top-circle absolute left-0 z-10 rounded-full bg-primary w-10 h-10 flex items-center justify-center">
                      <FaArrowRight className="arrow-icon text-secondary" />
                    </div>
                    <div className="text-primary ml-16">Get Started</div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>

            <div className="relative py-32">
              <div className="absolute inset-0">
                <img
                  src={feature}
                  className="w-full opacity-15 object-cover"
                  alt="feature"
                />
              </div>

              <div className="absolute inset-0">
                <img
                  src={floor}
                  className="w-full h-[80%] opacity-50 border-b border-[#2F4A71] absolute bottom-0"
                  alt="bg-floor"
                />
              </div>

              <div className="absolute inset-x-0"></div>
              <div className="relative container max-w-6xl mx-auto px-4 z-20">
                <motion.h2
                  className="text-3xl font-normal mb-8 text-center text-white"
                  variants={sectionHeadingVariants}
                  initial="hidden"
                  animate={controls}
                >
                  Transform Your Customer Experience with <br />{" "}
                  <span className="text-primary">
                    Advanced AI Chatbot Solutions
                  </span>
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {features.map((feature) => (
                    <motion.div
                      className="bg-secondary text-white p-6 rounded-lg shadow-lg hover:scale-105 transition-transform flex flex-col"
                      key={feature.title}
                      variants={featureCardVariants}
                      initial="hidden"
                      animate={controls}
                    >
                      <div className="flex items-center justify-start text-white mb-4 gap-3">
                        <div className="p-4 rounded-md text-yellow-300">
                          {feature.icon}
                        </div>
                        <h3 className="text-lg font-bold mb-0">
                          {feature.title}
                        </h3>
                      </div>
                      <p className="text-gray-500">{feature.description}</p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section id="plans" className="py-32">
            <h2 className="text-3xl font-normal mb-8 text-center text-white">
              Elevate Your Business with Our Comprehensive <br />{" "}
              <span className="text-primary">AI Chatbot Plans</span>
            </h2>
            <div className="flex items-center justify-center px-4">
              <div className="w-full max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 items-center h-full">
                {plans.map((plan, index) => (
                  <div
                    key={plan.id}
                    className="flex justify-center items-center h-full"
                  >
                    <div
                      className={`${
                        index === 1
                          ? "bg-primary text-black p-6 rounded-lg shadow-lg w-full max-w-md md:max-w-lg h-[500px] md:h-[600px] transform transition-transform hover:scale-105"
                          : "bg-secondary p-6 rounded-lg shadow-lg w-full max-w-xs md:max-w-sm h-[500px] md:h-[500px] transform transition-transform hover:scale-105"
                      } flex flex-col items-center justify-center text-center`}
                    >
                      <h3
                        className={`${
                          index === 1
                            ? "text-2xl font-extrabold mb-4"
                            : "text-white text-2xl font-extrabold mb-4"
                        }`}
                      >
                        {index === 0 ? ""  : plan.name}
                      </h3>
                      <span className="text-5xl font-extrabold">
                        {getPriceDisplay(index )}
                      </span>
                      {index === 1 && (
                        <div className="flex flex-col items-center mt-2">
                          <div className="flex items-center space-x-2">
                            <span className="text-5xl font-extrabold text-bg diagonal-line-through">
                              $150
                            </span>
                            <span className="text-xl text-black font-extrabold">
                              ${plan.price}
                            </span>
                            <span className="!ml-0">/month</span>
                          </div>
                          <div className="discount-box mt-2">
                            {`${(((150 - plan.price) / 150) * 100).toFixed(
                              0
                            )}% Off / Save $${(150 - plan.price).toFixed(2)}*`}
                          </div>
                        </div>
                      )}

                      <div
                        className="mt-4 flex-grow"
                        dangerouslySetInnerHTML={{
                          __html: modifyInclusions(plan.inclusions, index),
                        }}
                      />
                      <button
                        className={`h-12 w-full border-2 rounded-2xl flex items-center justify-center transition-transform transform hover:scale-110 hover:cursor-pointer mt-4 ${
                          index === 1
                            ? "hover:bg-black hover:border-black border-black hover:text-white"
                            : "hover:bg-primary hover:border-primary hover:text-black hover:shadow-lg hover:shadow-yellow-500"
                        }`}
                        onClick={() =>
                          index === 2
                            ? handleNavClick("support")
                            : handleRegClick()
                        }
                      >
                        <span>{index === 2 ? "Contact Us" : "Select Plan"}</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="support" className="pb-32">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-normal mb-4 text-center text-white">
                We're Here to Help with Your <br />{" "}
                <span className="text-primary">AI Chatbot Needs</span>
              </h2>
              <h3 className="text-center text-xl mb-8">Get in Touch With Us</h3>
              <div className="max-w-6xl mx-auto flex flex-col md:flex-row bg-secondary rounded-lg shadow-lg border border-gray-500">
                <div className="w-full p-8 space-y-6">
                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-col md:flex-row gap-6">
                      <div className="flex flex-col flex-1">
                        <label htmlFor="name" className="text-gray-400 mb-2">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="bg-gray-800 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                          value={form.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="flex flex-col flex-1">
                        <label htmlFor="email" className="text-gray-400 mb-2">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="bg-gray-800 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                          value={form.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="phone" className="text-gray-400 mb-2">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="bg-gray-800 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                        value={form.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="message" className="text-gray-400 mb-2">
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        className="bg-gray-800 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                        rows={5}
                        value={form.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="text-start mt-6 flex flex-col sm:flex-row justify-between items-center gap-4">
                      <ReCAPTCHA
                        sitekey={import.meta.env.VITE_SITE_KEY as string}
                        onChange={handleRecaptchaChange}
                      />
                      <button
                        type="submit"
                        className="bg-primary border border-primary text-black px-6 py-3 rounded-lg hover:bg-secondary hover:border hover:border-primary hover:text-white transition-colors"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Sending..." : "Send Message"}
                      </button>
                    </div>
                    {submissionMessage && (
                      <div
                        className={`mt-4 text-center ${
                          submissionMessage.includes("successfully")
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {submissionMessage}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </section>
          <div className="container max-w-6xl mx-auto pb-32  px-4 text-center">
            <div className="relative rounded-lg shadow-lg text-white overflow-hidden">
              <div
                className="absolute inset-0"
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  opacity: 0.2,
                }}
              ></div>
              <div className="relative p-10 bg-gray-900 bg-opacity-50 rounded-lg">
                <h2 className="text-4xl font-bold mb-4">
                  READY TO <span className="text-primary">GET STARTED?</span>
                </h2>
                <p className="text-gray-300 mb-8">
                  Discover the power of Rupeni, which is designed to revolutionize
                  your business operations. Enhance efficiency, drive engagement,
                  and achieve remarkable growth with our AI Powered chatbot. Join
                  us today and transform the way you work with AI.
                </p>
                <div className="flex flex-col md:flex-row flex-wrap justify-center gap-6 mb-8">
                  <div className="flex items-center gap-3">
                    <img src={check} className="w-6" alt="Check icon" />
                    <span>Unlimited AI-Powered Support</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <img src={check} className="w-6" alt="Check icon" />
                    <span>Instant, Accurate Responses</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <img src={check} className="w-6" alt="Check icon" />
                    <span>24/7 Customer Assistance</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <img src={check} className="w-6" alt="Check icon" />
                    <span>Easy Integration</span>
                  </div>
                </div>

                <div className="w-full flex justify-center">
                  <button
                    className="flex items-center mt-6 gap-4 relative parent-div hover:cursor-pointer"
                    onClick={() => handleRegClick()}
                  >
                    <div className="left-2 absolute rounded-full bg-blend w-10 h-10 flex items-center justify-center"></div>
                    <div className="top-circle absolute left-0 z-10 rounded-full bg-primary w-10 h-10 flex items-center justify-center">
                      <FaArrowRight className="arrow-icon text-secondary" />
                    </div>
                    <div className="text-primary ml-16">Get Started</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <footer className="bg-black text-white py-10">
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="flex flex-col items-center md:items-start">
                  <h4 className="text-xl font-bold mb-4 text-primary">
                    CONTACT INFORMATION
                  </h4>

                  <p className="footer-text text-gray-400 cursor-pointer">
                    <a href="mailto:info@rupeni.com">info@rupeni.com</a>
                  </p>
                  <p className="footer-text text-gray-400 cursor-pointer">
                    <a href="tel:+6799762017">(+679) 9762017</a>
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-start">
                    <h4 className="text-xl font-bold mb-4 text-primary">LINKS</h4>
                    <ul className="text-gray-400 space-y-2">
                      {sections.map((section) => (
                        <li key={section} className="footer-text">
                          <button
                            onClick={() => handleNavClick(section)}
                            className="cursor-pointer"
                          >
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                          </button>
                        </li>
                      ))}
                      <li className="footer-text">
                        <button
                          onClick={() => navigate("/authentication")}
                          className="cursor-pointer"
                        >
                          Get Started
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="flex flex-col items-center md:items-end">
                  <h4 className="text-xl font-bold mb-4 text-primary">
                    FOLLOW US
                  </h4>
                  <div className="flex space-x-4 md:mr-20  ">
                    <a
                      href="https://www.facebook.com/profile.php?id=61562199787705"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-text "
                    >
                      <FaFacebook className="footer-text w-8 h-8 hover:text-primary transition-colors" />
                    </a>
                    {/* <a
                      href="https://x.com/PacificBedbank"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter className="footer-text w-8 h-8 hover:text-primary transition-colors" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/2770225"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin className="footer-text w-8 h-8 hover:text-primary transition-colors" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-700 mt-8 pt-4 text-center">
                <p>Copyright © {new Date().getFullYear()} Rupeni AI</p>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
};

export default Landing;
