import { z } from "zod";

// Helper function to validate card number using Luhn algorithm
const validateLuhn = (number: string): boolean => {
  const digits = number.replace(/\D/g, "");
  let sum = 0;
  let isEven = false;

  // Loop through values starting from the rightmost
  for (let i = digits.length - 1; i >= 0; i--) {
    let digit = parseInt(digits[i]);

    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isEven = !isEven;
  }

  return sum % 10 === 0;
};

// Helper function to check if expiry date is valid
const isExpiryValid = (month: string, year: string): boolean => {
  const currentDate = new Date();
  const expiryDate = new Date(2000 + parseInt(year), parseInt(month) - 1);
  return expiryDate > currentDate;
};

export const creditCardSchema = z.object({
  cardName: z
    .string()
    .min(1, "Name is required")
    .regex(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be less than 50 characters"),

  cardNumber: z
    .string()
    .min(1, "Card number is required")
    .regex(/^[\d\s]+$/, "Card number can only contain numbers and spaces")
    .refine(
      (val) => val.replace(/\s/g, "").length >= 13,
      "Card number is too short"
    )
    .refine(
      (val) => val.replace(/\s/g, "").length <= 19,
      "Card number is too long"
    )
    .refine((val) => validateLuhn(val), "Invalid card number"),

  cvv: z
    .string()
    .min(1, "CVV is required")
    .regex(/^\d+$/, "CVV must contain only numbers")
    .refine(
      (val) => val.length >= 3 && val.length <= 4,
      "CVV must be 3 or 4 digits"
    ),

  expiry: z
    .string()
    .min(1, "Expiry date is required")
    .regex(/^\d{2}\/\d{2}$/, "Expiry date must be in MM/YY format")
    .refine((val) => {
      const [month] = val.split("/");
      const monthNum = parseInt(month);
      return monthNum >= 1 && monthNum <= 12;
    }, "Invalid month")
    .refine((val) => {
      const [month, year] = val.split("/");
      return isExpiryValid(month, year);
    }, "Card has expired"),
});

export type CreditCardSchema = z.infer<typeof creditCardSchema>;
