import React, { useState, useEffect, useCallback } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Button, Card } from "flowbite-react";
import {
  initiatePayment,
  getStatementsByUserId,
  addNewPaymentByUserId,
  updatePaymentStatus,
  getPaymentsByUserId,
  tokenizeCard,
  getToken,
  deleteToken,
} from "../../api/paymentplans";
import { useAuth } from "../../utils/helpers/authWrapper";
import { motion, AnimatePresence } from "framer-motion";
import { getCardTypeInfo } from "../../utils/helpers/getCardType";
import {
  creditCardSchema,
  type CreditCardSchema,
} from "../../utils/validation/creditCardSchema";
import { z } from "zod";

// Define the interface once at the top level
export interface Statement {
  amount: number;
  id: number;
  user_id: number;
  created_at: string;
  plan_name: string;
  plan_price: number;
}

export interface Payment {
  id: number;
  user_id: number;
  created_at: string;
  amount: number;
  status: string;
  masked_card_number?: string | null;
}

// Add this interface at the top level with other interfaces
interface NotificationState {
  show: boolean;
  message: string;
  type: "success" | "error";
}

// Update the PaymentStatusOverlay props
interface PaymentStatusOverlayProps {
  isPaymentPending: boolean;
  notification: NotificationState;
  setNotification: React.Dispatch<React.SetStateAction<NotificationState>>;
}

// Add these new interfaces
interface PaymentFormData {
  cardName: string;
  cardNumber: string;
  cvv: string;
  expiry: string;
}

// Add this new interface for the confirmation modal
interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
}

// Add this new interface for initial card data
interface InitialCardData {
  name?: string;
  brand?: string;
  number?: string;
  expiry?: string;
}

// Add this interface at the top level
interface TokenData {
  name: string;
  brand: string;
  number: string;
  expiry: string;
}

// Move these utility functions to the top of the file, before any component definitions
const formatCardNumber = (value: string) => {
  return value
    .replace(/\s/g, "")
    .replace(/(\d{4})/g, "$1 ")
    .trim();
};

const formatExpiry = (value: string) => {
  return value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1/$2");
};

// Format initial expiry from MMYY to MM/YY
const formatInitialExpiry = (expiry?: string) => {
  if (!expiry) return "";
  return `${expiry.slice(0, 2)}/${expiry.slice(2)}`;
};

// Add this utility function at the top with other formatting functions
const formatMaskedCardNumber = (value: string) => {
  // Remove any existing spaces
  const cleaned = value.replace(/\s/g, "");

  // Split into groups of 4 and join with spaces
  return cleaned.match(/.{1,4}/g)?.join(" ") || cleaned;
};

// Add this new component for the confirmation modal
function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  message,
  title = "Confirm Delete",
  confirmText = "Delete",
  cancelText = "Cancel",
}: Readonly<ConfirmationModalProps>) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
        <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
          {title}
        </h2>
        <p className="text-gray-600 dark:text-gray-300 mb-6">{message}</p>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition-colors"
          >
            {cancelText}
          </button>
          <button
            onClick={onConfirm}
            className={`px-4 py-2 rounded text-white transition-colors ${
              confirmText === "Delete"
                ? "bg-red-500 hover:bg-red-600"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}

// Update the PaymentForm component to handle update confirmation
function PaymentForm({
  onCancel,
  onSuccess,
  userId,
  isEditMode = false,
  onDelete,
  initialCardData,
}: {
  onCancel: () => void;
  onSuccess: (maskedNumber: string, tokenData?: TokenData) => void;
  userId: number;
  isEditMode?: boolean;
  onDelete?: () => void;
  initialCardData?: InitialCardData;
}) {
  const [formData, setFormData] = useState<PaymentFormData>({
    cardName: initialCardData?.name || "",
    cardNumber: initialCardData?.number
      ? formatMaskedCardNumber(initialCardData.number)
      : "",
    cvv: isEditMode ? "***" : "",
    expiry: initialCardData?.expiry
      ? formatInitialExpiry(initialCardData.expiry)
      : "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof CreditCardSchema, string>>
  >({});
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);

  const validateField = (field: keyof CreditCardSchema, value: string) => {
    try {
      creditCardSchema.shape[field].parse(value);
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors((prev) => ({ ...prev, [field]: error.errors[0].message }));
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // In edit mode, require new CVV (not "***")
      if (isEditMode && formData.cvv === "***") {
        setErrors((prev) => ({
          ...prev,
          cvv: "Please enter a new CVV",
        }));
        return;
      }

      creditCardSchema.parse(formData);

      if (isEditMode) {
        setShowUpdateConfirmation(true);
      } else {
        await saveCard();
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Partial<Record<keyof CreditCardSchema, string>> = {};
        error.errors.forEach((err) => {
          if (err.path[0]) {
            newErrors[err.path[0] as keyof CreditCardSchema] = err.message;
          }
        });
        setErrors(newErrors);
      }
    }
  };

  const handleCvvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Clear "***" when user starts typing
    if (formData.cvv === "***") {
      setFormData((prev) => ({ ...prev, cvv: value.slice(-1) }));
    } else {
      setFormData((prev) => ({ ...prev, cvv: value }));
    }
    // Validate new value if it's not "***"
    if (value !== "***") {
      validateField("cvv", value);
    }
  };

  const saveCard = async () => {
    const [month, year] = formData.expiry.split("/");

    try {
      // First delete the existing token if in edit mode
      if (isEditMode) {
        await deleteToken(userId);
      }

      // Then save the new card
      const response = await tokenizeCard({
        userId,
        nameOnCard: formData.cardName,
        cardNumber: formData.cardNumber.replace(/\s/g, ""),
        expiryMonth: month,
        expiryYear: year,
      });

      if (response && response.number) {
        // Get the updated token data
        const tokenResponse = await getToken(userId);
        onSuccess(response.number, tokenResponse?.token);
        onCancel();
      }
    } catch (error) {
      console.error("Error during card update:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <input
            type="text"
            placeholder="Name on Card"
            className={`w-full p-2 rounded bg-[#727272] text-white border ${
              errors.cardName ? "border-red-500" : "border-gray-300"
            } placeholder-gray-300`}
            value={formData.cardName}
            onChange={(e) => {
              const value = e.target.value;
              setFormData((prev) => ({ ...prev, cardName: value }));
              validateField("cardName", value);
            }}
          />
          {errors.cardName && (
            <p className="text-red-500 text-sm mt-1">{errors.cardName}</p>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="Card Number"
            maxLength={19}
            className={`w-full p-2 rounded bg-[#727272] text-white border ${
              errors.cardNumber ? "border-red-500" : "border-gray-300"
            } placeholder-gray-300`}
            value={formData.cardNumber}
            onChange={(e) => {
              const value = formatCardNumber(e.target.value);
              setFormData((prev) => ({ ...prev, cardNumber: value }));
              validateField("cardNumber", value);
            }}
          />
          {errors.cardNumber && (
            <p className="text-red-500 text-sm mt-1">{errors.cardNumber}</p>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="MM/YY"
            maxLength={5}
            className={`w-full p-2 rounded bg-[#727272] text-white border ${
              errors.expiry ? "border-red-500" : "border-gray-300"
            } placeholder-gray-300`}
            value={formData.expiry}
            onChange={(e) => {
              const value = formatExpiry(e.target.value);
              setFormData((prev) => ({ ...prev, expiry: value }));
              validateField("expiry", value);
            }}
          />
          {errors.expiry && (
            <p className="text-red-500 text-sm mt-1">{errors.expiry}</p>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="CVV"
            maxLength={4}
            className={`w-full p-2 rounded bg-[#727272] text-white border ${
              errors.cvv ? "border-red-500" : "border-gray-300"
            } placeholder-gray-300`}
            value={formData.cvv}
            onChange={handleCvvChange}
            onBlur={(e) => {
              if (!isEditMode || formData.cvv !== "***") {
                validateField("cvv", e.target.value);
              }
            }}
          />
          {errors.cvv && (
            <p className="text-red-500 text-sm mt-1">{errors.cvv}</p>
          )}
        </div>

        <div className="flex gap-2">
          <button
            type="submit"
            className="flex-1 border-2 border-white p-2 bg-transparent text-white rounded hover:bg-primary hover:border-primary hover:text-black transition-colors"
          >
            {isEditMode ? "Update" : "Save Payment Information"}
          </button>
          {isEditMode && onDelete && (
            <button
              type="button"
              onClick={onDelete}
              className="flex-1 border-2 border-red-500 p-2 bg-transparent text-red-500 rounded hover:bg-red-500 hover:text-white transition-colors"
            >
              Delete
            </button>
          )}
        </div>
      </form>

      <ConfirmationModal
        isOpen={showUpdateConfirmation}
        onClose={() => setShowUpdateConfirmation(false)}
        onConfirm={async () => {
          await saveCard();
          setShowUpdateConfirmation(false);
        }}
        message="Are you sure you want to update your payment method? Your current card will be replaced with the new one."
        title="Confirm Update"
        confirmText="Update"
        cancelText="Cancel"
      />
    </>
  );
}




// Update the CardDisplay component
function CardDisplay({
  maskedNumber,
  showForm,
  setShowForm,
  setMaskedNumber,
  refreshPayments,
  userId,
  tokenData,
  setTokenData,
}: {
  maskedNumber: string | null;
  showForm: boolean;
  setShowForm: (show: boolean) => void;
  setMaskedNumber: (number: string) => void;
  refreshPayments?: () => Promise<void>;
  userId: number;
  tokenData?: TokenData;
  setTokenData: (data?: TokenData) => void;
}) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteToken(userId);
      setMaskedNumber("");
      setTokenData(undefined);
      setShowDeleteConfirmation(false);
      setShowForm(false);
      if (refreshPayments) {
        await refreshPayments();
      }
    } catch (error) {
      console.error("Error deleting token:", error);
    }
  };

  // Add these variables before the return statement
  const cardNumber = tokenData?.number || maskedNumber || "";
  const lastFour = cardNumber.slice(-4);
  const cardInfo = getCardTypeInfo(cardNumber.replace(/\s/g, ""));

  if (!maskedNumber && !showForm) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="text-gray-300 italic"
      >
        No payment method saved
      </motion.div>
    );
  }

  return (
    <>
      {showForm ? (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
        >
          <PaymentForm
            userId={userId}
            onCancel={() => setShowForm(false)}
            onSuccess={(number, newTokenData) => {
              setMaskedNumber(number);
              if (newTokenData) {
                setTokenData(newTokenData);
              }
              if (refreshPayments) {
                refreshPayments();
              }
            }}
            isEditMode={!!maskedNumber}
            onDelete={() => setShowDeleteConfirmation(true)}
            initialCardData={tokenData}
          />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ type: "spring", duration: 0.6 }}
          className="flex items-center justify-between"
        >
          <div className="flex items-center space-x-3">
            {cardInfo &&
              React.createElement(cardInfo.icon, { className: "h-5 w-7" })}
            <div className="text-white">
              <span className="font-medium">{cardInfo?.name}</span>
              {lastFour && <span className="ml-2">**** {lastFour}</span>}
            </div>
          </div>
          <button
            onClick={() => setShowForm(true)}
            className="border-2 border-white p-2 px-6 bg-transparent text-white rounded hover:bg-primary hover:border-primary hover:text-black transition-colors"
          >
            Edit
          </button>
        </motion.div>
      )}

      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDelete}
        message="Are you sure you want to delete this payment method? This action cannot be undone."
        title="Delete Payment Method"
      />
    </>
  );
}

// Add this helper function near your other helpers
const formatExpiryDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

// Add this helper function near your other helpers
const formatBillingPeriod = (billingDate: string) => {
  const startDate = new Date(billingDate);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 30);

  return `${startDate.getDate()} ${startDate.toLocaleString("en-US", {
    month: "long",
  })} ${startDate.getFullYear()} - ${endDate.getDate()} ${endDate.toLocaleString(
    "en-US",
    { month: "long" }
  )} ${endDate.getFullYear()}`;
};

// Add these constants at the top of your file
const WS_USERNAME = "rupeni";
const WS_PASSWORD = "rupeni";

// Extract Processing Payment component
function ProcessingPaymentOverlay() {
  return (
    <motion.div
      key="processing"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
      transition={{ type: "spring", duration: 0.5 }}
      className="bg-white dark:bg-gray-800 p-10 rounded-xl shadow-2xl w-[40rem] min-h-[20rem] relative"
    >
      <div className="flex flex-col items-center justify-center h-full space-y-8">
        <div className="w-20 h-20 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
          <motion.svg
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            className="w-12 h-12 text-blue-500"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </motion.svg>
        </div>
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Processing Your Payment
          </h2>
          <div className="space-y-2">
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Please keep this window open while we process your payment
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              This usually takes less than a minute
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

// Extract Notification Overlay component
function NotificationOverlay({
  notification,
  setNotification,
}: Readonly<{
  notification: NotificationState;
  setNotification: React.Dispatch<React.SetStateAction<NotificationState>>;
}>) {
  const getColorClasses = (type: "success" | "error") => ({
    border: type === "success" ? "border-green-500" : "border-red-500",
    bg:
      type === "success"
        ? "bg-green-100 dark:bg-green-900"
        : "bg-red-100 dark:bg-red-900",
    text: type === "success" ? "text-green-500" : "text-red-500",
    button:
      type === "success"
        ? "bg-green-100 text-green-700 hover:bg-green-200 dark:bg-green-900 dark:text-green-100"
        : "bg-red-100 text-red-700 hover:bg-red-200 dark:bg-red-900 dark:text-red-100",
  });

  const colors = getColorClasses(notification.type);

  return (
    <motion.div
      key="notification"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
      transition={{ type: "spring", duration: 0.5 }}
      className={`bg-white dark:bg-gray-800 p-10 rounded-xl shadow-2xl w-[40rem] min-h-[20rem] relative border-l-8 ${colors.border}`}
    >
      <div className="flex flex-col items-center justify-center h-full space-y-8">
        <div
          className={`w-20 h-20 rounded-full 
          ${colors.bg} 
          flex items-center justify-center`}
        >
          <motion.svg
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: [0, 20, 0] }}
            transition={{ type: "spring", duration: 0.8 }}
            className={`w-12 h-12 
              ${colors.text}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            {notification.type === "success" ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.5}
                d="M5 13l4 4L19 7"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.5}
                d="M6 18L18 6M6 6l12 12"
              />
            )}
          </motion.svg>
        </div>
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            {notification.type === "success"
              ? "Payment Successful!"
              : "Payment Failed"}
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            {notification.message}
          </p>
          {notification.type === "success" && (
            <p className="text-sm text-gray-500 dark:text-gray-400">
              A confirmation email will be sent to your registered email address
            </p>
          )}
        </div>
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          onClick={() =>
            setNotification((prev: NotificationState) => ({
              ...prev,
              show: false,
            }))
          }
          className={`mt-6 px-6 py-2 rounded-lg font-medium transition-colors
            ${colors.button}`}
        >
          {notification.type === "success" ? "Done" : "Try Again"}
        </motion.button>
      </div>
    </motion.div>
  );
}

// Main overlay component with reduced complexity
function PaymentStatusOverlay({
  isPaymentPending,
  notification,
  setNotification,
}: Readonly<PaymentStatusOverlayProps>) {
  if (!isPaymentPending && !notification.show) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <AnimatePresence mode="wait">
        {isPaymentPending ? (
          <ProcessingPaymentOverlay />
        ) : (
          <NotificationOverlay
            notification={notification}
            setNotification={setNotification}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

// Move Pagination to module level
function Pagination({
  currentPage,
  pageCount,
  setPage,
}: Readonly<{
  currentPage: number;
  pageCount: number;
  setPage: (page: number) => void;
}>) {
  return (
    <div className="flex justify-center items-center mt-4">
      {Array.from({ length: pageCount }, (_, i) => i + 1).map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => setPage(pageNumber)}
          className={`px-3 py-1 text-sm ${
            currentPage === pageNumber
              ? "font-bold underline text-blue-600 dark:text-blue-400"
              : "text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
          }`}
        >
          {pageNumber}
        </button>
      ))}
    </div>
  );
}

// Move helper functions to module level
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .split(" ")
    .join(" ")
    .toUpperCase();
};

// Update the getLatestSuccessfulCard function to handle null payments
// const getLatestSuccessfulCard = (payments: Payment[] | null) => {
//   if (!payments || payments.length === 0) {
//     return null;
//   }

//   const successfulPayment = payments
//     .filter(
//       (payment) => payment.status === "complete" && payment.masked_card_number
//     )
//     .sort(
//       (a, b) =>
//         new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
//     )
//     .find((payment) => payment.masked_card_number);

//   return successfulPayment?.masked_card_number || null;
// };

// Add these new component interfaces
interface PaymentRowProps {
  payment: Payment;
  isLast: boolean;
}

interface PaymentTableProps {
  payments: Payment[];
  currentPage: number;
  itemsPerPage: number;
}

// Extract PaymentRow component
function PaymentRow({ payment, isLast }: Readonly<PaymentRowProps>) {
  const borderClass = !isLast
    ? "border-b-2 border-gray-300 dark:border-gray-700"
    : "";
  const baseClass = "py-4 font-bold text-black dark:text-white";

  return (
    <React.Fragment>
      <div className={`${baseClass} ${borderClass}`}>
        {formatDate(payment.created_at)}
      </div>
      <div className={`${baseClass} ${borderClass} text-center`}>
        ${payment.amount.toFixed(2)}
      </div>
      <div className={`${baseClass} ${borderClass} text-right`}>
        {payment.status.toUpperCase()}
      </div>
    </React.Fragment>
  );
}

// Extract PaymentTable component
function PaymentTable({
  payments,
  currentPage,
  itemsPerPage,
}: Readonly<PaymentTableProps>) {
  const paginatedPayments = payments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="w-full grid grid-cols-3 text-sm text-gray-500 dark:text-gray-400">
      {paginatedPayments.map((payment, index, array) => (
        <PaymentRow
          key={payment.id}
          payment={payment}
          isLast={index === array.length - 1}
        />
      ))}
    </div>
  );
}

// Add this helper function at module level
const updatePaymentInList = (
  payment: Payment,
  currentPaymentId: number | null,
  updatedPayment: Payment | undefined
): Payment => {
  if (payment.id === currentPaymentId && updatedPayment) {
    return updatedPayment;
  }
  return payment;
};

// Add this helper function at module level
const updatePaymentsList = (
  prevPayments: Payment[],
  currentPaymentId: number | null,
  updatedPayment: Payment | undefined
): Payment[] => {
  return prevPayments.map((payment) =>
    updatePaymentInList(payment, currentPaymentId, updatedPayment)
  );
};

function Billing() {
  const [showForm, setShowForm] = useState(false);
  const [statementsPage, setStatementsPage] = useState(1);
  const [paymentHistoryPage, setPaymentHistoryPage] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const { user, expiryDate } = useAuth();

  // Update the notification state with proper typing
  const [notification, setNotification] = useState<NotificationState>({
    show: false,
    message: "",
    type: "success",
  });

  const [isPaymentPending, setIsPaymentPending] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);
  const [statements, setStatements] = useState<Statement[]>([]);

  // Add state to store the current payment ID
  const [currentPaymentId, setCurrentPaymentId] = useState<number | null>(null);

  // Add state for payments
  const [payments, setPayments] = useState<Payment[]>([]);

  // Add this to get the masked number from the latest successful payment
  const [maskedNumber, setMaskedNumber] = useState<string | null>(null);

  // Add new payment to the table
  const addPaymentToTable = (newPayment: Payment) => {
    setPayments((prevPayments) => [newPayment, ...prevPayments]); // Add to start of array
  };

  // Calculate total balance due from all statements
  const totalBalanceDue = statements.reduce((total, statement) => {
    return total + statement.amount;
  }, 0);

  // Add this state to store token data
  const [tokenData, setTokenData] = useState<TokenData>();

  // Modified WebSocket connection
  useEffect(() => {
    const encodedAuth = btoa(`${WS_USERNAME}:${WS_PASSWORD}`);
    const ws = new WebSocket(
      `wss://payments.vaizee.com/ws?auth=${encodedAuth}`
    );

    ws.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      console.log(data.type);
      if (
        data.type === "payment_status" &&
        data.data.orderId === currentOrderId
      ) {
        setIsPaymentPending(false);

        if (currentPaymentId) {
          const updatedPayment = await updatePaymentStatus(
            currentPaymentId,
            data.data.status === "approved" ? "complete" : "declined",
            data.data.maskedPan || null,
            data.data.virtualPan || null
          );

          if (updatedPayment?.payment) {
            setPayments((prevPayments) =>
              updatePaymentsList(
                prevPayments,
                currentPaymentId,
                updatedPayment.payment
              )
            );
          }
        }

        if (data.data.status === "approved") {
          setNotification({
            show: true,
            message: "Payment processed successfully!",
            type: "success",
          });
          setCurrentOrderId(null);
          setCurrentPaymentId(null);
        } else if (data.data.status === "declined") {
          setNotification({
            show: true,
            message: "Payment was declined. Please try again.",
            type: "error",
          });
          setCurrentOrderId(null);
          setCurrentPaymentId(null);
        }
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsPaymentPending(false);
    };

    return () => {
      ws.close();
    };
  }, [currentOrderId, currentPaymentId]);

  // Add useEffect to fetch statements
  useEffect(() => {
    const fetchStatements = async () => {
      if (user?.id) {
        const response = await getStatementsByUserId(user.id);
        console.log("Fetched statements:", response);
        // Now this should work without type errors
        setStatements(response?.statements || []);
      }
    };

    fetchStatements();
  }, [user?.id]); // Dependency on user.id ensures it runs when user is loaded

  // Add useEffect to fetch payments
  useEffect(() => {
    const fetchPayments = async () => {
      if (user?.id) {
        const response = await getPaymentsByUserId(user.id);
        console.log("Fetched payments:", response);
        // Sort payments by date (most recent first)
        const sortedPayments = (response?.payments || []).sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setPayments(sortedPayments);
      }
    };

    fetchPayments();
  }, [user?.id]); // Dependency on user.id ensures it runs when user is loaded

  // Add this useEffect to fetch the token on mount
  useEffect(() => {
    const fetchSavedCard = async () => {
      if (user?.id) {
        const tokenData = await getToken(user.id);
        if (tokenData?.token) {
          setMaskedNumber(tokenData.token.number);
          setTokenData(tokenData.token);
        }
      }
    };

    fetchSavedCard();
  }, [user?.id]);

  const itemsPerPage = 5;

  const handlePayNow = async () => {
    try {
      setIsProcessing(true);

      const customerName = user?.name ?? "Unknown Customer";
      const amount = totalBalanceDue.toFixed(2);
      const orderId = Date.now().toString();
      setCurrentOrderId(orderId);

      const paymentResponse = await initiatePayment(customerName, amount);

      if (paymentResponse?.paymentLink && user?.id) {
        const newPayment = await addNewPaymentByUserId(
          user.id,
          parseFloat(amount),
          "pending"
        );

        console.log("New payment:", newPayment);

        if (newPayment?.payment) {
          setCurrentPaymentId(newPayment.payment.id);
          // Add the new payment to the table
          addPaymentToTable(newPayment.payment);
          window.open(paymentResponse.paymentLink);
          setIsProcessing(false);
          setIsPaymentPending(true);
        } else {
          throw new Error("Failed to record payment");
        }
      }
    } catch (error) {
      console.error("Payment process failed:", error);
      setIsProcessing(false);
      setIsPaymentPending(false);
      setNotification({
        show: true,
        message: "Failed to process payment. Please try again.",
        type: "error",
      });
    }
  };

  const refreshPayments = useCallback(async () => {
    if (user?.id) {
      const paymentsResponse = await getPaymentsByUserId(user.id);
      if (paymentsResponse) {
        setPayments(paymentsResponse.payments);
      }
    }
  }, [user?.id]);

  return (
    <MainLayout>
      <PaymentStatusOverlay
        isPaymentPending={isPaymentPending}
        notification={notification}
        setNotification={setNotification}
      />
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 px-4 py-6">
        <div className="lg:col-span-1 space-y-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Card className="px-4 py-2 bg-[#383838] dark:bg-gray-800 text-white text-center">
              <motion.div
                layout
                transition={{ duration: 0.3 }}
                className="flex flex-col items-center"
              >
                <motion.h1
                  layout="position"
                  className="text-gray-200 dark:text-gray-300"
                >
                  BALANCE DUE
                </motion.h1>
                <motion.h1 layout="position" className="text-2xl font-bold">
                  ${totalBalanceDue.toFixed(2)}
                </motion.h1>
                <motion.h1
                  layout="position"
                  className="text-gray-200 dark:text-gray-300"
                >
                  {user?.plan_name}
                </motion.h1>

                <AnimatePresence mode="wait">
                  {!showForm && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="w-full"
                    >
                      <Button
                        className="rounded-md bg-primary text-black dark:text-white w-full mt-2 hover:text-white dark:hover:bg-blue-600 transition-all duration-300"
                        onClick={() => {
                          if (!maskedNumber) {
                            const cardDisplayElement =
                              document.querySelector(".card-display");
                            if (cardDisplayElement) {
                              cardDisplayElement.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                            setShowForm(true);
                          } else {
                            handlePayNow();
                          }
                        }}
                        disabled={isProcessing}
                      >
                        {isProcessing ? (
                          <div className="flex items-center justify-center">
                            <svg
                              className="animate-spin h-5 w-5 mr-3"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                            Processing...
                          </div>
                        ) : maskedNumber ? (
                          "Pay Now"
                        ) : (
                          "Add Payment Method"
                        )}
                      </Button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Card className="px-4 py-2 bg-[#383838] dark:bg-gray-800 text-white text-start">
              <motion.div layout transition={{ duration: 0.3 }}>
                <div className="flex mb-2 justify-between items-center">
                  <motion.h1 layout="position" className="font-bold">
                    AUTOMATIC PAYMENT METHOD
                  </motion.h1>
                  {showForm && (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      onClick={() => setShowForm(false)}
                      className="text-gray-300 hover:text-white transition-colors"
                      aria-label="Close form"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </motion.button>
                  )}
                </div>

                <motion.div
                  layout
                  transition={{ duration: 0.3 }}
                  className="card-display"
                >
                  <AnimatePresence mode="wait">
                    <CardDisplay
                      maskedNumber={maskedNumber}
                      showForm={showForm}
                      setShowForm={setShowForm}
                      setMaskedNumber={setMaskedNumber}
                      refreshPayments={refreshPayments}
                      userId={user?.id || 0}
                      tokenData={tokenData}
                      setTokenData={setTokenData}
                    />
                  </AnimatePresence>
                </motion.div>

                <motion.hr
                  layout
                  transition={{ duration: 0.3 }}
                  className="my-3 border-t-2 border-white dark:border-gray-600"
                />

                <motion.div layout transition={{ duration: 0.3 }}>
                  <motion.h1 layout="position" className="font-bold">
                    Current Billing Cycle:
                  </motion.h1>
                  <motion.div layout className="flex flex-col">
                    <motion.span
                      layout="position"
                      className="text-gray-200 dark:text-gray-300"
                    >
                      Billing Period:{" "}
                      <span className="text-white dark:text-gray-100 font-bold">
                        {user?.billing_date
                          ? formatBillingPeriod(user.billing_date)
                          : "Not available"}
                      </span>
                    </motion.span>
                    <motion.span
                      layout="position"
                      className="text-gray-200 dark:text-gray-300"
                    >
                      Payment Due Date:{" "}
                      <span className="text-white dark:text-gray-100 font-bold">
                        {expiryDate
                          ? formatExpiryDate(expiryDate)
                          : "Not available"}
                      </span>
                    </motion.span>
                  </motion.div>
                  <motion.span
                    layout="position"
                    className="text-gray-200 dark:text-gray-300 text-xs mt-2 block"
                  >
                    *Your next payment will be due on the first day of your
                    billing cycle
                  </motion.span>
                </motion.div>
              </motion.div>
            </Card>
          </motion.div>
        </div>
        <div className="lg:col-span-3 space-y-6">
          <Card className="p-4 dark:bg-gray-800 bg-white">
            <h1 className="text-2xl font-bold text-start text-gray-900 dark:text-gray-100 mb-4">
              STATEMENTS
            </h1>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr className="border-b-2 border-gray-300">
                    <th scope="col" className="font-normal pb-3">
                      INVOICE DATE
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      BALANCE DUE
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      PLAN
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      TOTAL
                    </th>
                    <th
                      scope="col"
                      className="font-normal px-6 pb-3 text-center"
                    >
                      DOWNLOAD
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statements
                    .slice(
                      (statementsPage - 1) * itemsPerPage,
                      statementsPage * itemsPerPage
                    )
                    .map((statement, index, array) => (
                      <tr
                        key={statement.id}
                        className={`font-bold text-black dark:text-white ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        <td className="py-4">
                          {formatDate(statement.created_at)}
                        </td>
                        <td className="px-6 py-4">
                          ${statement.amount.toFixed(2)}
                        </td>
                        <td className="px-6 py-4">{statement.plan_name}</td>
                        <td className="px-6 py-4">
                          ${statement.plan_price.toFixed(2)}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-center items-center h-full">
                            <button
                              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
                              aria-label="Download invoice"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={statementsPage}
              pageCount={Math.ceil((statements || []).length / itemsPerPage)}
              setPage={setStatementsPage}
            />
          </Card>

          <Card className="p-4 dark:bg-gray-800 bg-white">
            <h1 className="text-2xl font-bold text-start mb-4 text-gray-900 dark:text-gray-100">
              PAYMENT HISTORY
            </h1>
            <div className="overflow-x-auto">
              <PaymentTable
                payments={payments || []}
                currentPage={paymentHistoryPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
            <Pagination
              currentPage={paymentHistoryPage}
              pageCount={Math.ceil((payments?.length || 0) / itemsPerPage)}
              setPage={setPaymentHistoryPage}
            />
          </Card>
        </div>
      </div>
    </MainLayout>
  );
}

export default Billing;
