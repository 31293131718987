import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../utils/helpers/authWrapper";
import useWebSocket from "react-use-websocket";
import { ChatSidebar } from "./components/ChatSidebar";
import { ChatContainer } from "./components/ChatContainer";
import { useChat } from "./hooks/useChat";
import { useAudio } from "./hooks/useAudio";
import { useWindowSize } from "./hooks/useWindowSize";
import { useDebounce } from "./hooks/useDebounce";
import { useWebSocketHandler } from "./hooks/useWebSocketHandler";
import { ChatLog } from "../../utils/types/chat";
import { AssignedChat, HumanAssistanceRequest } from "./types/index";

const getApiUrl = () => {
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;
  return env === "production"
    ? import.meta.env.VITE_REACT_APP_PRODUCTION_API_URL
    : import.meta.env.VITE_REACT_APP_DEVELOPMENT_API_URL;
};

const SUPPORT_SOCKET_URL = (support_id: string, support_name: string) => {
  const baseUrl = getApiUrl().replace("https:", "wss:");
  return `${baseUrl}/chat?type=support&support_id=${support_id}&support_name=${support_name}`;
};

export function Chats() {
  // Core chat state and handlers
  const {
    chatLogs,
    selectedLog,
    isLoading,
    hasMore,
    intervenedChats,
    highlightedMessageIds,
    chatMessages,
    lastHumanAssistanceRequest,
    setSelectedLog,
    setHighlightedMessageIds,
    setLastHumanAssistanceRequest,
    fetchChatLogs,
    fetchUpdatedLog,
    handleIntervene,
    handleReleaseChat,
    updateChatMessages,
    isLoadingMore,
    setChatLogs,
    handleWebSocketMessage,
  } = useChat();

  // UI state
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDomainId, setSelectedDomainId] = useState<number | null>(null);
  const [activeCallSessions, setActiveCallSessions] = useState<Set<string>>(
    new Set()
  );
  const [assignedChats, setAssignedChats] = useState<Map<string, AssignedChat>>(
    new Map()
  );
  const processedMessages = useRef(new Set<string>());
  const lastMessageRef = useRef<string | null>(null);
  const pendingMessages = useRef(new Map<string, string>());

  // Hooks
  const auth = useAuth();
  const user = auth.user;

  const location = useLocation();
  const locationState = location.state as {
    highlightedSessionId?: string;
    humanAssistanceRequest?: HumanAssistanceRequest;
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const debouncedSearch = useDebounce(searchQuery, 500);
  const { hasUserInteracted } = useAudio();
  const { sendMessage: sendSupportMessage, lastMessage } = useWebSocket(
    SUPPORT_SOCKET_URL(user?.id.toString() ?? "", user?.name ?? ""),
    {
      shouldReconnect: () => true,
    }
  );

  // WebSocket handler
  useWebSocketHandler({
    setHighlightedMessageIds,
    setLastHumanAssistanceRequest,
    setActiveCallSessions,
    fetchUpdatedLog,
    hasUserInteracted,
    intervenedChats,
  });

  const handleIncomingMessage = useCallback(
    (
      sessionId: string,
      message: string,
      messageId: string,
      isFromWebSocket: boolean
    ) => {
      if (processedMessages.current.has(messageId)) {
        console.log("Skipping duplicate message:", messageId);
        return;
      }

      processedMessages.current.add(messageId);
      console.log("Processing new message:", messageId);

      if (intervenedChats.has(sessionId)) {
        setChatLogs((prevLogs) =>
          prevLogs.map((log) => {
            if (log.session_id === sessionId) {
              const messagePrefix = isFromWebSocket ? "User: " : "Support: ";
              const updatedMessage = log.message.endsWith(message)
                ? log.message
                : log.message + "\n\n" + messagePrefix + message;
              return { ...log, message: updatedMessage };
            }
            return log;
          })
        );

        if (selectedLog && selectedLog.session_id === sessionId) {
          if (!selectedLog.message.endsWith(message)) {
            const messagePrefix = isFromWebSocket ? "User: " : "Support: ";
            const updatedMessage =
              selectedLog.message + "\n\n" + messagePrefix + message;
            const updatedLog = { ...selectedLog, message: updatedMessage };
            setSelectedLog(updatedLog);
            updateChatMessages(updatedLog);
          }
        }

        if (isFromWebSocket) {
          pendingMessages.current.delete(messageId);
        }
      }
    },
    [selectedLog, updateChatMessages, intervenedChats]
  );

  // Handle outgoing messages
  const handleSendMessage = useCallback(
    (messageText: string) => {
      if (selectedLog && selectedLog.session_id) {
        const messageId = `${
          selectedLog.session_id
        }-${messageText}-${Date.now()}`;

        pendingMessages.current.set(messageId, messageText);

        handleIncomingMessage(
          selectedLog.session_id,
          messageText,
          messageId,
          false
        );

        const messageData = {
          type: "support_message",
          domain: selectedLog.domain,
          clientID: selectedLog.session_id,
          message: messageText,
        };
        sendSupportMessage(JSON.stringify(messageData));
      }
    },
    [selectedLog, handleIncomingMessage, sendSupportMessage]
  );

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (!lastMessage?.data) return;

    if (lastMessageRef.current === lastMessage.data) {
      console.log("Skipping duplicate WebSocket message");
      return;
    }

    try {
      lastMessageRef.current = lastMessage.data;
      const data = JSON.parse(lastMessage.data);
      console.log("Received WebSocket message:", data);

      let messageId: string;

      switch (data.type) {
        case "chat_message":
          if (!data.session_id || !data.message) {
            console.log("Invalid chat message format:", data);
            return;
          }

          messageId = `${data.session_id}-${data.message}`;
          if (intervenedChats.has(data.session_id)) {
            handleIncomingMessage(
              data.session_id,
              data.message,
              messageId,
              true
            );
          }
          break;

        case "chat_assigned":
          setAssignedChats((prev) => {
            const newMap = new Map(prev);
            newMap.set(data.sessionID, {
              sessionId: data.sessionID,
              agentName: data.agent,
              supportId: data.supportID,
            });
            return newMap;
          });
          break;

        case "chat_released":
          setAssignedChats((prev) => {
            const newMap = new Map(prev);
            newMap.delete(data.sessionID);
            return newMap;
          });
          break;
      }
    } catch (error) {
      console.error("Error processing WebSocket message:", error);
    }
  }, [lastMessage, handleIncomingMessage, intervenedChats]);

  // Polling effect
  useEffect(() => {
    const pollInterval = setInterval(() => {
      fetchChatLogs(false);
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(pollInterval);
  }, [fetchChatLogs]);

  // Update chat logs after polling
  useEffect(() => {
    if (selectedLog) {
      const updatedLog = chatLogs.find(
        (log) => log.session_id === selectedLog.session_id
      );
      if (updatedLog && updatedLog.message !== selectedLog.message) {
        setSelectedLog(updatedLog);
        updateChatMessages(updatedLog);
      }
    }
  }, [chatLogs, selectedLog, updateChatMessages]);

  // Location state handler
  useEffect(() => {
    if (location.state) {
      const { highlightedSessionId, isCall, sessionId } = location.state;
      const targetId = highlightedSessionId || (isCall ? sessionId : null);

      if (targetId) {
        const selected = chatLogs.find((log) => log.session_id === targetId);

        if (selected) {
          setSelectedLog(selected);
          setHighlightedMessageIds((prev) => new Set(prev).add(targetId));

          if (isCall) {
            setActiveCallSessions((prev) => new Set(prev).add(targetId));
          }
        }
      }
    }
  }, [chatLogs, location.state, setSelectedLog, setHighlightedMessageIds]);

  // Filter and sort logs
  const filteredAndSortedLogs = React.useMemo(() => {
    return chatLogs
      .filter((log) => {
        if (selectedDomainId && log.domain_id !== selectedDomainId) {
          return false;
        }
        if (debouncedSearch) {
          return log.message
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase());
        }
        return true;
      })
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
  }, [chatLogs, selectedDomainId, debouncedSearch]);

  const handleSelectLog = useCallback(
    (log: ChatLog) => {
      setSelectedLog(log);
      updateChatMessages(log);

      // If this log was highlighted, remove the highlight
      if (highlightedMessageIds.has(log.session_id)) {
        setHighlightedMessageIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(log.session_id);
          return newSet;
        });
      }
    },
    [setHighlightedMessageIds, updateChatMessages]
  );

  // Update chat logs after polling
  useEffect(() => {
    if (lastMessage?.data) {
      try {
        const data = JSON.parse(lastMessage.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    }
  }, [lastMessage, handleWebSocketMessage]);

  // Add this effect to handle the incoming state
  useEffect(() => {
    if (locationState?.humanAssistanceRequest) {
      setLastHumanAssistanceRequest(locationState.humanAssistanceRequest);

      // Also set the highlighted message IDs
      setHighlightedMessageIds((prev) =>
        new Set(prev).add(locationState.humanAssistanceRequest!.sessionID)
      );

      // Clear the location state to prevent re-processing
      window.history.replaceState({}, document.title);
    }
  }, [
    locationState?.humanAssistanceRequest,
    setLastHumanAssistanceRequest,
    setHighlightedMessageIds,
  ]);

  // Add with other refs near the top of the component
  const isFirstRender = useRef(true);

  // Add near other useEffects
  useEffect(() => {
    const viewChat = location.state?.viewChat;

    if (viewChat && isFirstRender.current) {
      // Add this chat to our existing logs if it's not already there
      setChatLogs((prevLogs) => {
        const exists = prevLogs.some((log) => log.id === viewChat.id);
        if (!exists) {
          return [viewChat, ...prevLogs];
        }
        return prevLogs;
      });
      setSelectedLog(viewChat);
      updateChatMessages(viewChat);

      // Clear the location state to prevent re-processing
      window.history.replaceState({}, document.title);
      isFirstRender.current = false;
    }
  }, [
    location.state?.viewChat,
    setSelectedLog,
    updateChatMessages,
    setChatLogs,
  ]);

  return (
    <MainLayout>
      <div className="px-4 pt-6 h-[calc(100vh-theme(spacing.32))]">
        {auth.loading ? (
          <Spinner />
        ) : (
          <div className="border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col rounded-none grid grid-cols-1 md:grid-cols-[25%_75%] p-0 h-full">
            <ChatSidebar
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              selectedDomainId={selectedDomainId}
              domains={auth.domains}
              onDomainSelect={setSelectedDomainId}
              filteredAndSortedLogs={filteredAndSortedLogs}
              isLoading={isLoading}
              hasMore={hasMore}
              onLoadMore={() => fetchChatLogs(false)}
              selectedLog={selectedLog}
              highlightedMessageIds={highlightedMessageIds as Set<string>}
              activeCallSessions={activeCallSessions}
              onSelectLog={handleSelectLog}
              isMobile={isMobile}
              isLoadingMore={isLoadingMore}
              assignedChats={assignedChats}
            />

            {(!isMobile || selectedLog) && (
              <ChatContainer
                selectedLog={selectedLog}
                chatMessages={chatMessages}
                intervenedChats={intervenedChats}
                activeCallSessions={activeCallSessions}
                lastHumanAssistanceRequest={lastHumanAssistanceRequest}
                onSendMessage={handleSendMessage}
                onIntervene={handleIntervene}
                onReleaseChat={handleReleaseChat}
                assignedChats={assignedChats}
                currentUserId={user?.id.toString() ?? ""}
              />
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default Chats;
