import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { useAuth } from "../helpers/authWrapper";
import useWebSocket from "react-use-websocket";
import {
  sendBrowserNotification,
  requestNotificationPermission,
} from "../notifications";
import { usePermissions } from "../hooks/usePermissions";

interface Notification {
  id: string; // Add a unique id for each notification
  message: string;
  sessionId: string;
  domain: string;
  type: "human_assistance_request" | "new_call_request"; // Update the type here
  caller?: string; // Add a caller for call requests
}

interface CallNotification extends Notification {
  type: "new_call_request";
  caller: string;
}

type NotificationType = Notification | CallNotification;

interface WebSocketContextType {
  lastMessage: MessageEvent<unknown> | null;
  listenerMessage: MessageEvent<unknown> | null;
  supportMessage: MessageEvent<unknown> | null;
  sendMessage: (message: string, type?: "listener" | "support") => void;
  notifications: NotificationType[]; // Change to an array of notifications
  clearNotification: (id: string) => void; // Update to clear a specific notification
  clearAllNotificationsForSession: (sessionId: string) => void;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

// Rename this custom hook to avoid naming conflicts
export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      "useWebSocketContext must be used within a WebSocketProvider"
    );
  }
  return context;
};

const getApiUrl = () => {
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;
  return env === "production"
    ? import.meta.env.VITE_REACT_APP_PRODUCTION_API_URL
    : import.meta.env.VITE_REACT_APP_DEVELOPMENT_API_URL;
};

const getWebSocketUrl = (type: "human_listener" | "support") => {
  const baseUrl = getApiUrl().replace("https:", "wss:");
  return `${baseUrl}/chat?type=${type}`;
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authenticated, user, domains } = useAuth();
  const { can } = usePermissions();
  const canTakeOver = can("chat_takeover");
  const [listenerSocketUrl, setListenerSocketUrl] = useState<string | null>(
    null
  );
  const [supportSocketUrl, setSupportSocketUrl] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<NotificationType[]>([]); // Change to an array

  const notificationTimeoutsRef = useRef<Map<string, NodeJS.Timeout>>(
    new Map()
  );

  const isDomainAuthorized = useCallback(
    (domainToCheck: string) => {
      return (
        domains?.some((domain) => domain.domain === domainToCheck) ?? false
      );
    },
    [domains]
  );

  // Set up both socket URLs when authenticated
  useEffect(() => {
    if (authenticated && user) {
      setListenerSocketUrl(getWebSocketUrl("human_listener"));
      setSupportSocketUrl(getWebSocketUrl("support"));
    } else {
      setListenerSocketUrl(null);
      setSupportSocketUrl(null);
    }
  }, [authenticated, user]);

  // Connect to both WebSockets with sendMessage capability
  const { lastMessage: listenerMessage, sendMessage: sendListenerMessage } =
    useWebSocket(listenerSocketUrl, {
      shouldReconnect: () => authenticated,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    });

  const { lastMessage: supportMessage, sendMessage: sendSupportMessage } =
    useWebSocket(supportSocketUrl, {
      shouldReconnect: () => authenticated,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    });

  // Create a sendMessage function that sends to the appropriate socket
  const sendMessage = (
    message: string,
    type: "listener" | "support" = "support"
  ) => {
    try {
      if (type === "listener") {
        sendListenerMessage(message);
      } else {
        sendSupportMessage(message);
      }
    } catch (error) {
      console.error(`Error sending ${type} message:`, error);
    }
  };

  // Add notification permission request on mount
  useEffect(() => {
    requestNotificationPermission();
  }, []);

  // Handle support messages (both call requests and human assistance)
  useEffect(() => {
    // Skip notifications for viewers
    if (!canTakeOver || !supportMessage) return;

    try {
      const parsedData = JSON.parse(supportMessage.data);
      const requestDomain = parsedData.domain || parsedData.data?.domain;

      // Skip if domain is not authorized
      if (!isDomainAuthorized(requestDomain)) {
        console.log(
          `Skipping notification for unauthorized domain: ${requestDomain}`
        );
        return;
      }

      // Handle human assistance requests
      if (parsedData.type === "human_assistance_request") {
        const notification: NotificationType = {
          id: Date.now().toString(),
          type: "human_assistance_request" as const,
          message: "Human assistance requested",
          sessionId: parsedData.sessionID,
          domain: parsedData.domain,
        };

        setNotifications((prev) => [...prev, notification]);

        // Send browser notification if not focused
        sendBrowserNotification({
          title: "New Chat Request",
          body: `New chat request from ${parsedData.domain}`,
          onClick: () => {
            window.location.href = "/chats";
          },
        });

        // Auto-clear notification after 2 minutes
        const timeout = setTimeout(() => {
          clearNotification(notification.id);
        }, 120 * 1000);

        notificationTimeoutsRef.current.set(notification.id, timeout);
      }

      // Handle call requests (existing logic)
      else if (parsedData.type === "new_call_request") {
        const notification: CallNotification = {
          id: Date.now().toString(),
          type: "new_call_request" as const,
          message: "Incoming call",
          sessionId: parsedData.data.session_id,
          domain: parsedData.data.domain,
          caller: parsedData.data.name || "Unknown Caller",
        };

        setNotifications((prev) => [...prev, notification]);

        sendBrowserNotification({
          title: "Incoming Call",
          body: `Incoming call from ${notification.caller}`,
          onClick: () => {
            window.location.href = "/chats";
          },
        });
      }
    } catch (error) {
      console.error("Error parsing supportMessage.data:", error);
    }
  }, [supportMessage, canTakeOver, isDomainAuthorized]);

  const clearNotification = (id: string) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const clearAllNotificationsForSession = (sessionId: string) => {
    setNotifications((prev) => prev.filter((n) => n.sessionId !== sessionId));
  };

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      notificationTimeoutsRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        lastMessage: listenerMessage,
        listenerMessage,
        supportMessage,
        sendMessage, // Now this is a real function
        notifications,
        clearNotification,
        clearAllNotificationsForSession,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
