import React, { useState } from "react";
import { authenticateUser, invalidateAuth } from "../../api/authentication";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";

interface LoginComponentProps {
  setTab: (tab: string) => void;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ setTab }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
      return error.message;
    }
    return String(error);
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage("");
    setLoading(true);
    try {
      const response = await authenticateUser({ email, password, rememberMe });
      if (response === "Login successful!") {
        setMessage(response);
        invalidateAuth(queryClient);
        await queryClient.refetchQueries({ queryKey: ["auth-session"] });
        navigate("/dashboard");
      } else {
        setMessage(response || "An error occurred. Please try again later.");
      }
    } catch (error) {
      setMessage(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const formVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const childVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <div className="flex  items-center justify-center lg:col-span-3 p-4">
      <div className="bg-white  p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10">
        <motion.h2
          className="text-3xl font-bold text-center mb-8 text-gray-800"
          variants={childVariants}
          initial="initial"
          animate="animate"
        >
          Login to Your Account
        </motion.h2>
        {message && (
          <p
            className={`text-center mb-4 ${
              message === "Login successful!"
                ? "text-green-500"
                : "text-red-500"
            }`}
            aria-live="polite"
          >
            {message}
          </p>
        )}
        <motion.form
          onSubmit={handleLogin}
          variants={formVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          <motion.div variants={childVariants}>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <div className="relative mb-8">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none text-gray-400">
                <i className="fas fa-user"></i>
              </div>
              <input
                type="email"
                id="email-login"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </motion.div>

          <motion.div variants={childVariants}>
            <div className="mb-8">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <div className="relative mb-8">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none text-gray-400">
                  <i className="fas fa-lock"></i>
                </div>
                <input
                  type="password"
                  id="password-login"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            variants={childVariants}
            className="mb-4 flex items-center"
          >
            <input
              type="checkbox"
              id="rememberMe-login"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label
              htmlFor="rememberMe-login"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember Me
            </label>
          </motion.div>

          <motion.div
            variants={childVariants}
            className="flex items-center justify-between mb-8"
          >
            <button
              className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
              type="submit"
              disabled={loading}
            >
              <span className="text-sm p-8">
                {loading ? "Loading..." : "Sign In"}
              </span>
            </button>
            <button
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-700 cursor-pointer"
              onClick={() => setTab("forgot-password")}
            >
              Forgot Password?
            </button>
          </motion.div>

          <motion.div variants={childVariants} className="relative my-8">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">OR</span>
            </div>
          </motion.div>
        </motion.form>

        <motion.div
          variants={childVariants}
          initial="initial"
          animate="animate"
          className="text-center mt-8"
        >
          <p className="text-sm text-gray-500">
            Don't have an account?{" "}
            <button
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
              onClick={() => setTab("registration")}
            >
              Sign Up
            </button>
          </p>
        </motion.div>
        <div className="absolute inset-0 bg-gradient-to-r from-purple-300 to-transparent opacity-10 pointer-events-none"></div>
      </div>
    </div>
  );
};

export default LoginComponent;
